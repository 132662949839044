import { StyleSheet, Text, View } from "react-native";
import React, { useCallback, useState } from "react";
import { Searchbar, useTheme } from "react-native-paper";
import { customModalStyle } from "../../../components/CustomModal/customModalStyle";
import { FlatList } from "react-native-gesture-handler";
import NoData from "../../../components/NoData";

const PlatformListPopUp = ({ record, data, isLoading = false }) => {
  let theme = useTheme();
  const [searchTerm, setSearchTerm] = useState("");

  const getOptions = useCallback(() => {
    let filteredData = [...data];
    if (searchTerm !== "") {
      filteredData = filteredData.filter((element) =>
        element.store_name.toString().toLocaleLowerCase().includes(searchTerm.trim().toLocaleLowerCase())
      );
    }
    return filteredData;
  }, [searchTerm, data]);

  return (
    <View>
      <View style={styles(theme).headerContainer}>
        <Text style={customModalStyle(theme).title}>
          {record
            ? "Report Version - " + record?.name + " (" + data.length + ")"
            : ""}
        </Text>
      </View>
      {!isLoading && (
        <Searchbar
          placeholder={"Search..."}
          onChangeText={(query) => {
            setSearchTerm(query);
          }}
          value={searchTerm}
        />
      )}
      <FlatList
        ListEmptyComponent={<NoData />}
        data={getOptions()}
        keyExtractor={(item, index) => index}
        renderItem={({ item }) => (
          <View style={styles(theme).renderMenuItemContainer}>
            <View style={styles(theme).renderMenuItemTextLabelContainer}>
              <Text numberOfLines={1}>{item?.store_name}</Text>
            </View>
          </View>
        )}
        style={styles(theme).listContainer}
      />
    </View>
  );
};

export default PlatformListPopUp;

const styles = (theme) =>
  StyleSheet.create({
    renderMenuItemContainer: {
      flexDirection: "row",
      alignItem: "center",
      width: 500,
    },
    renderMenuItemTextLabelContainer: {
      justifyContent: "center",
      flex: 1,
      paddingHorizontal: 10,
      paddingVertical: 6,
    },
    listContainer: {
      height: 250,
      borderWidth: 1,
      marginVertical: 10,
      borderRadius: 5,
      borderColor: theme.colors.divider,
      width: 500,
    },
    headerContainer: { marginBottom: 5 },
  });
