import { View } from "react-native";
import React, { useContext, useState } from "react";
import { Searchbar, useTheme } from "react-native-paper";
import { commonstyle } from "../Accounts/AccountEditPage/commonstyle";
import {
  ActionsButtonIcons,
  CommonDataTable,
  commonStatisticButtonStyle,
} from "../../../components";
import { ACTION_BUTTON_TYPE } from "../../../constants/GlobalVariables";
import PaymentContextAPI from "./Reducer/PaymentsContextAPI";
import { config } from "./payment.config";
import { SELECTED_ROW } from "./Reducer/actions";
import { formatCurrency } from "../../../utils/formatCurrency";
import dayjs from "dayjs";

const PaymentTable = () => {
  let theme = useTheme();
  let {
    state: { tableData },
    dispatch,
  } = useContext(PaymentContextAPI);

  const [searchQuery, setSearchQuery] = useState("");
  const onChangeSearch = (query) => setSearchQuery(query);

  const onClickViewDetailPage = (record) => {
    dispatch({ type: SELECTED_ROW, payload: record });
  };

  let columns = [
    {
      key: "id",
      title: "ID",
      dataIndex: "id",
      sort: true,
      search: true,
      width: 1.5,
      // bold: true,
    },
    {
      key: "client_id",
      title: "Client ID",
      dataIndex: "client_id",
      sort: true,
      search: true,
      filter: true,
      width: 1.7,
    },
    {
      key: "client_name",
      title: "Client Name",
      dataIndex: "client_name",
      sort: true,
      search: true,
      filter: true,
      width: 3,
    },
    {
      key: "currency",
      title: "Currency",
      dataIndex: "currency",
      sort: true,
      search: true,
      filter: true,
      width: 3,
    },
    {
      key: "date_requested",
      title: "Date Requested",
      dataIndex: "date_requested",
      sort: true,
      filter: true,
      width: 2.5,
      isDate: true,
      customToolTip: (date) => {
        return date ? dayjs(date).format("DD/MM/YYYY") : null
      },
      render: (date) => {
        return date ? dayjs(date).format("DD/MM/YYYY") : null
      },
    },
    {
      key: "remittance_date",
      title: "Remittance Date",
      dataIndex: "remittance_date",
      sort: true,
      filter: true,
      width: 2.5,
      isDate: true,
      customToolTip: (date) => {
        return date ? dayjs(date).format("DD/MM/YYYY") : null
      },
      render: (date) => {
        return date ? dayjs(date).format("DD/MM/YYYY") : null
      },
    },
    {
      key: "netamount",
      title: "Amount",
      dataIndex: "netamount",
      sort: true,
      width: 1.5,
      render: (currency) => {
        return formatCurrency(currency);
      },
    },
    {
      key: "type",
      title: "Type",
      dataIndex: "type",
      sort: true,
      filter: true,
      width: 1.75,
    },
    {
      key: "paid_via",
      title: "Paid Via",
      dataIndex: "paid_via",
      sort: true,
      filter: true,
      width: 2.5,
    },
    {
      key: "no_of_invoices",
      title: "No. Of Invoices",
      dataIndex: "no_of_invoices",
      sort: true,
      filter: true,
      width: 2.5,
    },
    {
      key: "status",
      title: "Payment Status",
      dataIndex: "status",
      sort: true,
      filter: true,
      width: 2.6,
    },
    {
      key: "approved_by",
      title: "Approval By",
      dataIndex: "approved_by",
      sort: true,
      filter: true,
      width: 2.6,
    },
    {
      key: "View",
      title: "View",
      dataIndex: "View",
      center: true,
      // hideToolTip: true,
      width: 1,
      customToolTip: () => {
        return "View more details";
      },
      render: (id, record) => {
        return (
          <ActionsButtonIcons
            buttonType={ACTION_BUTTON_TYPE.FILE_SEARCH}
            handleOnPress={() => {
              onClickViewDetailPage(record);
            }}
          />
        );
      },
    },
  ];

  return (
    <View>
      <View style={commonstyle(theme).searchTableWrapper}>
        <Searchbar
          placeholder={config.TABLE_SEARCH_PLACEHOLDER}
          onChangeText={onChangeSearch}
          value={searchQuery}
          style={commonStatisticButtonStyle(theme, 700).searchBar}
          inputStyle={commonStatisticButtonStyle(theme).searchBarInput}
        />
      </View>
      <CommonDataTable
        canFilterColumns
        columns={columns}
        data={tableData}
        resetPage={searchQuery}
        searchQuery={searchQuery}
        tableMaxHeight={600}
      />
    </View>
  );
};

export default PaymentTable;
