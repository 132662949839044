import { View } from "react-native";
import React, { useState } from "react";
import { Checkbox, Searchbar, useTheme } from "react-native-paper";
import {
  CommonDataTable,
  commonStatisticButtonStyle,
} from "../../../../components";
import { config } from "./account.config";
import { commonstyle } from "./commonstyle";
import dayjs from "dayjs";
import { updateSelectedRow } from "./updateSelectedRow";

const ClientsTable = ({
  data,
  selectActiveTab,
  updateTable,
  canSelect = true,
}) => {
  let theme = useTheme();
  const [searchQuery, setSearchQuery] = useState("");
  const onChangeSearch = (query) => setSearchQuery(query);

  const onPressUpdateSelectedRow = (id) => {
    let finalData = updateSelectedRow(data, id, "client_id");
    updateTable(finalData);
  };

  let commonColumns = [
    {
      key: "client_id",
      title: "ID",
      dataIndex: "client_id",
      width: 0.4,
      // bold: true,
      sort: true,
      search: true,
    },
    {
      key: "name",
      title: "Individual / Company Name",
      dataIndex: "name",
      // bold: true,
      width: 1.5,
      sort: true,
      search: true,
    },
    {
      key: "client_type",
      title: "Client Type",
      dataIndex: "client_type",
      width: 1,
      // bold: true,
      sort: true,
      search: true,
    },
    {
      key: "email",
      title: "Email",
      dataIndex: "email",
      width: 2,
      sort: true,
      search: true,
    },
    {
      key: "identification",
      title: "Personal / Tax Identification Number",
      dataIndex: "identification",
      width: 2,
      sort: true,
      search: true,
    },
    {
      key: "registration_date",
      title: "Registration Date",
      dataIndex: "registration_date",
      width: 1,
      sort: true,
      render: (date) => {
        return date ? dayjs(date).format("DD/MM/YYYY") : null
      },
    },
    {
      key: "advance",
      title: "Advance",
      dataIndex: "advance",
      width: 0.5,
      sort: true,
    },
  ];

  let selectColumns = [
    {
      key: "existing",
      title: "",
      dataIndex: "existing",
      width: 0.3,
      hideToolTip: true,
      render: (existing, record) => {
        return (
          <Checkbox
            status={existing ? "checked" : "unchecked"}
            onPress={() => {
              onPressUpdateSelectedRow(record?.client_id);
            }}
          />
        );
      },
    },
  ];

  return (
    <View>
      <View style={commonstyle(theme).searchTableWrapper}>
        <View>
          <Searchbar
            placeholder={config.CLIENT_SEARCH_PLACEHOLDER}
            onChangeText={onChangeSearch}
            value={searchQuery}
            style={commonStatisticButtonStyle(theme, 760).searchBar}
            inputStyle={commonStatisticButtonStyle(theme).searchBarInput}
          />
        </View>
        {selectActiveTab && <View>{selectActiveTab}</View>}
      </View>
      <CommonDataTable
        columns={
          canSelect ? [...selectColumns, ...commonColumns] : commonColumns
        }
        data={data}
        resetPage={searchQuery}
        searchQuery={searchQuery}
      />
    </View>
  );
};

export default ClientsTable;
