import { StyleSheet } from "react-native";

export const commonDataTablestyle = (
  theme,
  width,
  bold,
  minHeight,
  maxHeight,
  alignCenter,
  alignLeft,
  mobileView,
  selected = false,
  rowBackgroundColor = theme.colors.card
) =>
  StyleSheet.create({
    datatableContainer: {
      backgroundColor: mobileView
        ? theme.colors.customCardColor
        : theme.colors.card,
      minHeight: minHeight,
      maxHeight: maxHeight,
    },
    columnWidth: {
      flex: width ? width : 3,
      justifyContent: alignCenter
        ? "center"
        : alignLeft
          ? "flex-end"
          : "flex-start",
      backgroundColor: selected
        ? theme.colors.selectedTableBackgroundColor
        : rowBackgroundColor,
      paddingHorizontal: 2,
      flexDirection: "row",
    },
    columnTitleWidth: {
      flex: width ? width : 3 - 0.5,
      justifyContent: alignCenter
        ? "center"
        : alignLeft
          ? "flex-end"
          : "flex-start",
      backgroundColor: selected
        ? theme.colors.selectedTableBackgroundColor
        : rowBackgroundColor,
      paddingHorizontal: 2,
      flexDirection: "row",
    },
    datatableTitle: {
      backgroundColor: theme.colors.card,
      fontFamily: theme.customFonts.regular,
      color: theme.colors.datatableTitleText,
      fontSize: 12,
      textAlign: alignCenter ? "center" : alignLeft ? "flex-end" : "flex-start",
    },
    datatableFooterTotal: {
      fontFamily: theme.customFonts.bold,
      color: theme.colors.black,
      fontSize: 14,
      textAlign: alignCenter ? "center" : alignLeft ? "flex-end" : "flex-start",
    },
    datatableCell: {
      fontFamily: bold ? theme.customFonts.bold : theme.customFonts.regular,
      fontSize: 14,
      color: theme.colors.text,
      paddingHorizontal: 2,
    },
    tableHeader: {
      backgroundColor: theme.colors.card,
      flexDirection: "row",
      alignItems: "center",
      paddingVertical: 15,
      flex: 1,
      borderBottomWidth: 1,
      borderBottomColor: theme.colors.divider,
    },
  });

export const tooltipStyle = (theme) =>
  StyleSheet.create({
    container: {
      // backgroundColor: theme.colors.text,
      padding: 4,
      margin: -4,
    },
    textMessage: {
      color: theme.colors.background,
      fontFamily: theme.customFonts.regular,
      textAlign: "center",
    },
  });
