import { StyleSheet, Text, View } from "react-native";
import React, { useContext } from "react";
import AdjustmentContextAPI from "./AdjustmentContextAPI";
import {
  CustomButton,
  Statistics,
  WebContentContainerWrapper,
  commonStatisButtonCustomStyle,
  commonStatisticButtonStyle,
} from "../../../components";
import {
  BUTTON_TEXTS,
  STATISTICS_DATA,
  TABS,
  config,
} from "./adjustment.config";
import { customStyle } from "../../../components/CustomText/customStyle";
import { BUTTON_TYPE } from "../../../constants/GlobalVariables";
import { useTheme } from "react-native-paper";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import MultiSelectFilter from "../Reports/CustomReportFilter/MultiSelectFilter";
import CompareAdjustmentsFromFilters from "./CompareAdjustmentsFromFilters";
import ComparisonMatrix from "./ComparisonMatrix";
import FormRadioGroup from "../../../components/FormControl/FormRadio/FormRadioGroup";
import { accountEditTableStyle } from "../Accounts/AccountEditPage/AccountEditTables";
import FormRadioButton from "../../../components/FormControl/FormRadio/FormRadioButton";
import {
  CHANGE_ACTIVE_TAB,
  RESET_FILTERS,
  UPDATE_MULTIPLE_DATA,
} from "./Reducer/actions";
import AdjustmentTable from "./AdjustmentTable";
import SingleSelectFilter from "../Reports/CustomReportFilter/SingleSelectFilter";
import { getLegendTextColor } from "./getLegendTextColorCode";
import { formatCurrency } from "../../../utils/formatCurrency";

const LandingPage = () => {
  let {
    state: {
      loading,
      activeTab,
      client_id,
      clientOptions,
      service_id,
      serviceOptions,
      reporting_year,
      yearOptions,
      compareMatrixData,
      total_diff,
    },
    dispatch,
  } = useContext(AdjustmentContextAPI);
  let theme = useTheme();

  const onTabChange = (tab) => {
    dispatch({ type: CHANGE_ACTIVE_TAB, payload: tab });
  };

  const onChangeMultiSelectFilter = (value) => {
    let tempData = { ...value, compareMatrixData: [], compareTableData: [] };
    dispatch({
      type: UPDATE_MULTIPLE_DATA,
      payload: tempData,
    });
  };

  const onChangeYear = (name, value) => {
    let payload = {
      compareMatrixData: [],
      compareTableData: [],
      [name]: value,
    };
    dispatch({ type: UPDATE_MULTIPLE_DATA, payload: payload });
  };

  const onClearAllFilter = () => {
    dispatch({
      type: RESET_FILTERS,
      payload: {},
    });
  };

  const getCurrencyFormated = (currency) =>
    `${currency > 0 ? "+" : ""}${formatCurrency(currency)}`;

  const extraHeaderComponent = (
    <>
      <View style={styles.headerContainer}>
        <Text style={customStyle(theme).headerText20}>
          Make your selection to generate a comparison
        </Text>
        <CustomButton
          buttontype={BUTTON_TYPE.SECONDARY}
          onPress={onClearAllFilter}
          text={
            <View style={styles.clearBtnTextContainer}>
              <MaterialCommunityIcons
                name="restart"
                size={20}
                color={theme.colors.black}
              />
              <Text>Restart</Text>
            </View>
          }
        />
      </View>
      <View style={styles.filtersContainer}>
        <MultiSelectFilter
          optionName="client_name"
          optionValue="client_id"
          multipleSelect={false}
          name={config.CLIENT_KEY}
          title={config.CLIENT_NAME}
          placeholdertext={config.CLIENTS_TITLE}
          value={client_id}
          options={clientOptions}
          onChange={onChangeMultiSelectFilter}
        />
        <SingleSelectFilter
          placeholdertext={config.YEAR_TITLE}
          name={config.YEAR_NAME}
          value={reporting_year}
          options={yearOptions}
          onChange={onChangeYear}
        />
        <MultiSelectFilter
          optionName="name"
          optionValue="id"
          multipleSelect={false}
          name={config.SERVICE_KEY}
          title={config.SERVICE_NAME}
          placeholdertext={config.SERVICE_TITLE}
          value={service_id}
          options={serviceOptions}
          onChange={onChangeMultiSelectFilter}
        />
      </View>
      <CompareAdjustmentsFromFilters />
      {
        client_id.length > 0 &&
        reporting_year &&
        service_id.length > 0 &&
        compareMatrixData.length > 0 && (
          <View style={styles.statisticContainer}>
            <View style={commonStatisticButtonStyle(theme).statisticContainer}>
              <Statistics label={STATISTICS_DATA.REPORT_VERISION_TITLE} value={"V1"} />
              <Statistics
                label={STATISTICS_DATA.EUR_TOTAL_DIFF}
                value={getCurrencyFormated(total_diff.EUR)}
                color={getLegendTextColor(getCurrencyFormated(total_diff.EUR), theme)}
              />
              <Statistics
                label={STATISTICS_DATA.USD_TOTAL_DIFF}
                value={getCurrencyFormated(total_diff.USD)}
                color={getLegendTextColor(getCurrencyFormated(total_diff.USD), theme)}
              />
              <Statistics
                label={STATISTICS_DATA.GBP_TOTAL_DIFF}
                value={getCurrencyFormated(total_diff.GBP)}
                color={getLegendTextColor(getCurrencyFormated(total_diff.GBP), theme)}
              />
              <View style={commonStatisButtonCustomStyle(theme).buttonContainer}>
                <CustomButton
                  disabled
                  text={BUTTON_TEXTS.ADD_TO_NEXT_INVOICE_BTN}
                  cutomButtonStyle={commonStatisButtonCustomStyle(theme).btn}
                  textStyle={commonStatisButtonCustomStyle(theme).text}
                // onPress={() => {
                //   openShowNewAccountForm({});
                // }}
                />
                <CustomButton
                  disabled
                  text={BUTTON_TEXTS.UPGRADE_TO_V2}
                  cutomButtonStyle={commonStatisButtonCustomStyle(theme).downloadBtn}
                  textStyle={commonStatisButtonCustomStyle(theme).text}
                // onPress={() => {
                //   openShowNewAccountForm({});
                // }}
                />
                <CustomButton
                  text={BUTTON_TEXTS.DOWNLOAD_CSV}
                  cutomButtonStyle={commonStatisButtonCustomStyle(theme).downloadBtn}
                  textStyle={commonStatisButtonCustomStyle(theme).text}
                // onPress={() => {
                //   openShowNewAccountForm({});
                // }}
                />
              </View>
            </View>
          </View>
        )
      }
    </>
  );


  let selectActiveTab = (
    <FormRadioGroup
      inlineLabel
      label={"View:"}
      value={activeTab}
      onChange={(name, value) => {
        onTabChange(value);
      }}
    >
      <View style={accountEditTableStyle.radioButtonWrapper}>
        <FormRadioButton label={TABS.MATRIX_VIEW} value={TABS.MATRIX_VIEW} />
        <FormRadioButton label={TABS.TABLE_VIEW} value={TABS.TABLE_VIEW} />
      </View>
    </FormRadioGroup>
  );

  return (
    <WebContentContainerWrapper loading={loading} title={config.TITLE} extraHeaderComponent={extraHeaderComponent}>
      <>
        {client_id.length > 0 &&
          reporting_year &&
          service_id.length > 0 &&
          compareMatrixData.length > 0 && (
            <>
              {activeTab === TABS.MATRIX_VIEW ? (
                <ComparisonMatrix selectActiveTab={selectActiveTab} />
              ) : (
                <AdjustmentTable selectActiveTab={selectActiveTab} />
              )}
            </>
          )}
      </>
    </WebContentContainerWrapper>
  );
};

export default LandingPage;

const styles = StyleSheet.create({
  filtersContainer: {
    flexDirection: "row",
    gap: 10,
    flex: 1,
    justifyContent: "space-between",
  },
  headerContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  clearBtnTextContainer: { flexDirection: "row", gap: 5, alignItems: "center" },
  statisticContainer: { marginTop: 15 },
});
