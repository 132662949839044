import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import React, { useContext, useState } from "react";
import { Divider, Searchbar, useTheme } from "react-native-paper";
import { REPORT_STATUS, config } from "./report.config";
import {
  ActionsButtonIcons,
  CommonDataTable,
  ConfirmationWindow,
  CustomModal,
  commonStatisticButtonStyle,
} from "../../../components";
import ReportsContextAPI from "./ReportsContextAPI";
import SecondaryHeaderTextSemiBold from "../../../components/CustomText/SecondaryHeaderTextSemiBold";
import {
  FontAwesome,
  EvilIcons,
  AntDesign,
  MaterialCommunityIcons,
} from "@expo/vector-icons";
import {
  ACTION_BUTTON_TYPE,
  DEFAULT_ERROR_MSG,
} from "../../../constants/GlobalVariables";
import axios from "axios";
import useAxios from "../../../hooks/useAxios";
import {
  LOADER,
  REFRESH_TABLE,
  TABLE_LOADER,
  TABLE_PREVIEW_ROW,
} from "./Reducer/actions";
import {
  REPORTS_URL,
  TRIGGER_PROCESS_PENDING_REPORTS,
} from "../../../constants/GlobalUrls";
import { downloadFile } from "./downloadFileUsingURL";
import dayjs from "dayjs";
import { formatCurrency } from "../../../utils/formatCurrency";
import AuthContextAPI from "../../../context/AuthContextAPI";
import { checkNetworkReachable } from "../../../utils/checkNetworkReachable";

const GeneratedReportTable = () => {
  // api hook
  let api = useAxios();

  const { logoutUser } = useContext(AuthContextAPI);

  let theme = useTheme();
  // cancel token used to cancel the call if component is unmounted or need to programmatically cancel the request
  const cancelTokenSource = axios.CancelToken.source();
  let {
    state: { tableData },
    dispatch,
  } = useContext(ReportsContextAPI);

  const [searchQuery, setSearchQuery] = useState("");
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState({
    show: false,
    record: {},
  });

  const [resetPageToFirst, setResetPageToFirst] = useState(false);

  const onChangeSearch = (query) => setSearchQuery(query);

  const triggerRefreshTable = () => {
    dispatch({ type: REFRESH_TABLE, payload: false });
    triggerResetPageToFirst();
  };

  const triggerResetPageToFirst = () => {
    setResetPageToFirst(!resetPageToFirst);
  };

  const restartProcess = () => {
    if (
      window.confirm(
        `Do you want to restart report generation process for Pending and Failed reports?`
      )
    ) {
      triggerRestartProcessAPI();
    }
  };

  const closeDeleteConfirmationWindow = () => {
    setShowDeleteConfirmation({ ...showDeleteConfirmation, show: false });
  };
  const openDeleteConfirmationWindow = (rowData) => {
    setShowDeleteConfirmation({ show: true, record: rowData });
  };

  const handleDelete = async () => {
    dispatch({ type: TABLE_LOADER, payload: true });

    let id = showDeleteConfirmation?.record?.id;
    let url = `${REPORTS_URL}${id}/`;
    closeDeleteConfirmationWindow();

    const handleSuccessResponse = (res) => {
      if (res?.message) {
        alert(res.message);
      }
      triggerRefreshTable();
    };

    const handleErrorResponse = (error) => {
      checkNetworkReachable();
      if (error?.response?.status === 401) {
        logoutUser();
      } else if (!axios.isCancel(error) && error?.response) {
        alert(DEFAULT_ERROR_MSG);
      }
    };

    await api
      .delete(url, {
        cancelToken: cancelTokenSource.token,
      })
      .then((res) => {
        handleSuccessResponse(res.data);
      })
      .catch((error) => {
        handleErrorResponse(error);
        dispatch({ type: LOADER, payload: false });
      });
  };

  const triggerRestartProcessAPI = async () => {
    dispatch({ type: LOADER, payload: true });

    const handleSuccessResponse = (res) => {
      alert(res?.message);
    };

    await api
      .get(TRIGGER_PROCESS_PENDING_REPORTS, {
        cancelToken: cancelTokenSource.token,
      })
      .then((res) => {
        handleSuccessResponse(res.data);
        triggerResetPageToFirst();
      })
      .catch((error) => {
        checkNetworkReachable();
        if (error?.response?.status === 401) {
          logoutUser();
        } else if (!axios.isCancel(error) && error?.response) {
          alert(DEFAULT_ERROR_MSG);
        }
      });
    dispatch({ type: LOADER, payload: false });
  };

  const showDetailPage = (record) => {
    dispatch({ type: TABLE_PREVIEW_ROW, payload: record });
  };

  let columns = [
    {
      key: "id",
      title: "ID",
      dataIndex: "id",
      width: 0.1,
      sort: true,
      search: true,
    },
    {
      key: "reporttype",
      title: "Type",
      dataIndex: "reporttype",
      width: 0.2,
      sort: true,
      search: true,
      filter: true,
    },
    {
      key: "selection",
      title: "Selection",
      dataIndex: "selection",
      width: 0.4,
      sort: true,
      search: true,
      filter: true,
    },
    {
      key: "filename",
      title: "File Name",
      dataIndex: "filename",
      width: 1,
      sort: true,
      search: true,
      render: (filename, record) => {
        return (
          <Text
            style={styles(theme).linkText}
            onPress={() => {
              showDetailPage(record);
            }}
          >
            {filename}
          </Text>
        );
      },
    },
    {
      key: "createdat",
      title: "Created At",
      dataIndex: "createdat",
      width: 0.55,
      sort: true,
      isDate: true,
      customToolTip: (date) => {
        return date ? dayjs(date).format("DD/MM/YYYY HH:mm:ss") : null;
      },
      render: (date) => {
        return date ? dayjs(date).format("DD/MM/YYYY HH:mm:ss") : null;
      },
    },
    {
      key: "createdby",
      title: "Created By",
      dataIndex: "createdby",
      width: 0.34,
      sort: true,
      search: true,
      filter: true,
    },
    {
      key: "startperiod",
      title: "Start Period",
      dataIndex: "startperiod",
      width: 0.36,
      sort: true,
      search: true,
      filter: true,
    },
    {
      key: "endperiod",
      title: "End Period",
      dataIndex: "endperiod",
      width: 0.35,
      sort: true,
      search: true,
      filter: true,
    },
    {
      key: "neteur",
      title: "Net EUR",
      dataIndex: "neteur",
      width: 0.4,
      sort: true,
      render: (currency) => {
        return formatCurrency(currency);
      },
    },
    {
      key: "netusd",
      title: "Net USD",
      dataIndex: "netusd",
      width: 0.4,
      sort: true,
      render: (currency) => {
        return formatCurrency(currency);
      },
    },
    {
      key: "netgbp",
      title: "Net GBP",
      dataIndex: "netgbp",
      width: 0.4,
      sort: true,
      render: (currency) => {
        return formatCurrency(currency);
      },
    },
    {
      key: "status",
      title: "Status",
      dataIndex: "status",
      width: 0.25,
      sort: true,
      center: true,
      filter: true,
      render: (active, report) => {
        return active === REPORT_STATUS.COMPLETED ? (
          <FontAwesome name="circle" size={14} color={theme.colors.primary} />
        ) : active === REPORT_STATUS.PENDING ? (
          <EvilIcons
            name="spinner"
            size={19}
            color={theme.colors.editBtnIcon}
          />
        ) : (
          <AntDesign
            name="infocirlce"
            size={16}
            color={theme.colors.error}
            onPress={() => {
              alert(report?.msg);
            }}
          />
        );
      },
    },
    {
      key: ACTION_BUTTON_TYPE.CLOUD_DOWNLOAD,
      title: "Download",
      dataIndex: "status",
      width: 0.3,
      center: true,
      hideToolTip: true,
      render: (status, record) => {
        return (
          <View style={styles(theme).downloadBtnContainer}>
            <ActionsButtonIcons
              active={status === REPORT_STATUS.COMPLETED}
              buttonType={ACTION_BUTTON_TYPE.CLOUD_DOWNLOAD}
              handleOnPress={() => {
                downloadFile(record?.id, record.csvurl);
              }}
            />
            <Divider style={styles(theme).downloadBtnDivider} />
            <ActionsButtonIcons
              active={status === REPORT_STATUS.COMPLETED}
              buttonType={ACTION_BUTTON_TYPE.DOWNLOAD_EXCEL}
              handleOnPress={() => {
                downloadFile(record?.id, record.xlsurl);
              }}
            />
          </View>
        );
      },
    },
    {
      key: ACTION_BUTTON_TYPE.DELETE,
      title: "Delete",
      dataIndex: "id",
      width: 0.15,
      center: true,
      hideToolTip: true,
      render: (id, record) => {
        return (
          <ActionsButtonIcons
            active={record?.status === REPORT_STATUS.COMPLETED}
            buttonType={ACTION_BUTTON_TYPE.DELETE}
            handleOnPress={() => {
              openDeleteConfirmationWindow(record);
            }}
          />
        );
      },
    },
  ];

  return (
    <View>
      <View style={styles(theme).searchTableContainer}>
        <Searchbar
          placeholder={config.GENERATE_REPORT_SEARCH_PLACHOLDER}
          onChangeText={onChangeSearch}
          value={searchQuery}
          style={styles(theme, 650).searchBar}
          inputStyle={commonStatisticButtonStyle(theme).searchBarInput}
        />
        <View style={styles(theme).totalRefreshContainer}>
          <TouchableOpacity
            style={styles(theme).refreshBtnContainer}
            onPress={() => {
              triggerRefreshTable();
            }}
          >
            <View style={styles(theme).tableActionBtn}>
              <MaterialCommunityIcons
                name="table-refresh"
                size={20}
                color={theme.colors.black}
              />
              <Text>Refresh</Text>
            </View>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles(theme).refreshBtnContainer}
            onPress={() => {
              restartProcess();
            }}
          >
            <View style={styles(theme).tableActionBtn}>
              <MaterialCommunityIcons
                name="restart"
                size={20}
                color={theme.colors.black}
              />
              <Text>Restart</Text>
            </View>
          </TouchableOpacity>
          <SecondaryHeaderTextSemiBold text={`Total: ${tableData.length}`} />
        </View>
      </View>

      <CommonDataTable
        canFilterColumns
        tableMaxHeight={590}
        columns={columns}
        data={tableData}
        resetPage={searchQuery || resetPageToFirst}
        searchQuery={searchQuery}
      />
      <CustomModal
        visible={showDeleteConfirmation.show}
        hideModal={closeDeleteConfirmationWindow}
      >
        <ConfirmationWindow
          isDelete
          message={`Are you sure you want to delete ${showDeleteConfirmation?.record?.filename} report with ID ${showDeleteConfirmation?.record?.id}? If yes, type in delete below`}
          handleNoOnClick={closeDeleteConfirmationWindow}
          handleYesOnClick={handleDelete}
        />
      </CustomModal>
    </View>
  );
};

export default GeneratedReportTable;

const styles = (theme, searchBarWidth) =>
  StyleSheet.create({
    searchTableContainer: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: 32,
      marginBottom: 16,
    },
    searchBar: {
      height: 36,
      width: searchBarWidth,
    },
    totalRefreshContainer: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      gap: 10,
    },
    refreshBtnContainer: {
      borderWidth: 1,
      alignItems: "center",
      justifyContent: "center",
      width: "fit-content",
      padding: 5,
      borderRadius: 5,
      borderColor: theme.colors.editBtnIcon,
    },
    downloadBtnContainer: {
      flexDirection: "row",
      gap: 4,
      alignItems: "center",
    },
    downloadBtnDivider: {
      width: 2,
      height: 24,
      backgroundColor: theme.colors.divider,
    },
    linkText: { color: theme.colors.linkColor },
    tableActionBtn: { flexDirection: "row", gap: 4, alignItems: "center" },
  });
