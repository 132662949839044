import React from "react";
import { View } from "react-native";
import ExpensesListingPage from "./ExpensesListingPage/ExpensesListingPage";

const Expenses = () => {
  return (
    <View>
      <ExpensesListingPage />
    </View>
  );
};

export default Expenses;
