export const addEditArrayOfObject = (arr, rowData, record) => {
  let tempData = [...arr];
  if (Object.keys(record).length > 0) {
    let foundIndex = tempData.findIndex((obj) => {
      if (record === "New") {
        return obj.key === record.key
      }
      else {
        return obj.id === record.id
      }
    });


    tempData[foundIndex] = rowData;
  } else {
    tempData.push(rowData);
  }
  return tempData;
};
