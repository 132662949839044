import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { ACTION_BUTTON_TYPE } from '../../../../constants/GlobalVariables';
import { ActionsButtonIcons, CommonDataTable, ConfirmationWindow, CustomModal } from '../../../../components';
import { addEditArrayOfObject } from '../../../../utils/addEditArrayOfObject';
import { deleteTableRecord } from '../../../../utils/deleteTableRecord';
import { TABLE_ACTION } from './ContractManagerDealForm.config';
import { FormLabel } from "../../../../components/FormControl";
import ExpensesForm from './ExpensesForm';

const ExpensesTable = ({ getExpenseData, expensesData = [], expenseFor }) => {
    const [showExpenseForm, setShowExpenseForm] = useState({
        showForm: false,
        record: {},
    });
    const [tableData, setTableData] = useState([]);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState({
        show: false,
        record: {},
    });

    useEffect(() => {
        let isMoulded = true;
        if (isMoulded && expensesData.length > 0) {
            setTableData(expensesData);
        }
        return () => {
            isMoulded = false;
        };
    }, [expensesData]);

    const setExpenseData = (rowData) => {
        let tempData = addEditArrayOfObject(tableData, rowData, record);
        setTableData(tempData);
        if (getExpenseData) {
            getExpenseData(tempData);
        }
        closeShowExpenseForm();
    };

    const handleDelete = () => {
        let id = showDeleteConfirmation.record.id;
        let tempData = [...tableData];
        if (id === "New") {
            tempData = deleteTableRecord(tableData, showDeleteConfirmation.record);
        } else {
            let obj = tempData.find((item) => item.id === id);
            obj.action = TABLE_ACTION.delete;
        }
        setTableData(tempData);
        getExpenseData(tempData);
        closeDeleteConfirmationWindow();
    };
    const { showForm, record } = showExpenseForm;

    const openShowExpenseForm = (rowdData = {}) => {
        setShowExpenseForm({
            showForm: true,
            record: rowdData,
        });
    };
    const closeShowExpenseForm = () => {
        setShowExpenseForm({
            showForm: false,
            record: {},
        });
    };

    const closeDeleteConfirmationWindow = () => {
        setShowDeleteConfirmation({ ...showDeleteConfirmation, show: false });
    };
    const openDeleteConfirmationWindow = (rowData) => {
        setShowDeleteConfirmation({ show: true, record: rowData });
    };
    let columns = [
        {
            key: "id",
            title: "Expense ID",
            dataIndex: "id",
            width: 1,
            // bold: true,
        },
        {
            key: "expensetype",
            title: "Expense Type",
            dataIndex: "expensetype",
            // bold: true,
        },
        {
            key: "expensedate",
            title: "Expense Date",
            dataIndex: "expensedate",
        },
        {
            key: "amount",
            title: "Amount",
            dataIndex: "amount",
        },
        {
            key: "currencycode",
            title: "Currency",
            dataIndex: "currencycode",
        },
        {
            key: ACTION_BUTTON_TYPE.EDIT,
            title: "Edit",
            dataIndex: "key",
            width: 0.6,
            center: true,
            hideToolTip: true,
            render: (id, row) => {
                return (
                    <ActionsButtonIcons
                        active={row?.action !== TABLE_ACTION.delete}
                        buttonType={ACTION_BUTTON_TYPE.EDIT}
                        handleOnPress={() => {
                            openShowExpenseForm(row);
                        }}
                    />
                );
            },
        },
        {
            key: ACTION_BUTTON_TYPE.REMOVE,
            title: "Remove",
            dataIndex: "key",
            width: 0.6,
            center: true,
            hideToolTip: true,
            render: (id, row) => {
                return (
                    <ActionsButtonIcons
                        active={row?.action !== TABLE_ACTION.delete}
                        buttonType={ACTION_BUTTON_TYPE.REMOVE}
                        handleOnPress={() => {
                            openDeleteConfirmationWindow(row);
                        }}
                    />
                );
            },
        },
    ];

    return (
        <View>
            <FormLabel bold text={"Expenses"} />
            <CommonDataTable
                columns={columns}
                data={tableData}
                showAddButton
                handleAddBtnClick={() => {
                    openShowExpenseForm({});
                }}
                showPagination={false}
            />
            <CustomModal
                title={`${Object.keys(record).length > 0 ? `Edit Expense: ${record?.id}` : "New Expense"}`}
                visible={showForm}
                hideModal={closeShowExpenseForm}
            >
                <ExpensesForm
                    data={record}
                    cancelButton={closeShowExpenseForm}
                    getExpenseData={setExpenseData}
                    expenseFor={expenseFor}
                />
            </CustomModal>
            <CustomModal
                title={`Expense ID: ${showDeleteConfirmation?.record?.id}`}
                visible={showDeleteConfirmation.show}
                hideModal={closeDeleteConfirmationWindow}
            >
                <ConfirmationWindow
                    isDelete
                    message={`Are you sure you want to delete ${showDeleteConfirmation?.record?.type} expense in the amount of ${showDeleteConfirmation?.record?.amount} ${showDeleteConfirmation?.record?.currencycode}? If yes, type in delete below`}
                    handleNoOnClick={closeDeleteConfirmationWindow}
                    handleYesOnClick={handleDelete}
                />
            </CustomModal>
        </View>
    );
}

export default ExpensesTable;
