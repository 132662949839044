export const config = {};
export const DEAL_TYPES = {
  LABEL: "label",
  LABEL_NAME: "Label",
  RELEASE: "release",
  RELEASE_NAME: "Release",
  TRACK_ON_UPC: "track_on_upc",
  TRACK_ON_UPC_NAME: "Track on UPC",
  ASSET: "asset",
  ASSET_NAME: "Asset",
  CHANNEL: "channel",
  CHANNEL_NAME: "Channel"
};


export const ADVANCES_TYPES = {
  DEAL: "Deal",
  CONTRACT: "Contract",
  CLIENT: "Client",
};

export const EXPENSES_TYPES = {
  DEAL: "Deal",
  CONTRACT: "Contract",
  CLIENT: "Client",
};

export const advancesCategoryOptions = [
  {
    title: "Deal",
    value: ADVANCES_TYPES.DEAL,
  },
  {
    title: "Contract",
    value: ADVANCES_TYPES.CONTRACT,
  },
  {
    title: ADVANCES_TYPES.CLIENT,
    value: ADVANCES_TYPES.CLIENT,
  },
];

export const TABLE_ACTION = {
  add: "add",
  update: "update",
  delete: "delete",
  no_action: "",
};