import axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import { Pressable, View } from "react-native";
import { useTheme } from "react-native-paper";
import {
  commonFormStyle,
  CustomButton,
  WebContentContainerWrapper,
  CustomModal,
  ConfirmationWindow,
  ActionsButtonIcons,
} from "../../../../components";
import {
  FormSelect,
  FormControlInput,
} from "../../../../components/FormControl";
import MessageValidation from "../../../../components/MessageValidation";
import {
  CONTRACTS_REGISTRATION,
  DEAL_DASHBOARD_URL,
  GET_ALL_SERVICE_PROVIDER,
  LABELS_URL,
  CONTRACTS_RELEASES_URL,
  TRACK_URL,
  GET_DEAL_TYPE_URL,
  GET_ALL_ASSET_DROPDOWN_URL,
  GET_CHANNEL_DROPDOWN_URL,
} from "../../../../constants/GlobalUrls";
import {
  ACTION_BUTTON_TYPE,
  BUTTON_TEXTS,
  CONDFIRM_EDIT_MESSAGE,
  DEFAULT_ERROR_MSG,
  DEFAULT_REQUIRED_VALIDATION_MESSAGE,
  FORM_REQUIRED_FIELD_MSG,
  MESSAGE_TYPE,
} from "../../../../constants/GlobalVariables";
import ContractFormContextAPI from "../../../../context/ContractFormContextAPI";
import useAxios from "../../../../hooks/useAxios";
import { checkObjectIsEmpty } from "../../../../utils/checkObjectIsEmpty";
import { findAllByKey } from "../../../../utils/findAllByKey";
import { config } from "../ContractManager.config";
import AdvancesTable from "./AdvancesTable";
import {
  ADVANCES_TYPES,
  DEAL_TYPES,
  TABLE_ACTION,
} from "./ContractManagerDealForm.config";
import LabelForm from "./LabelForm";
import AddMoreField from "../../../../components/AddMoreField/AddMoreField";
import { config as missingDspDealsConfig } from "../../MissingDspDeals/missingDspDeals.config";
import { AntDesign } from "@expo/vector-icons";
import AuthContextAPI from "../../../../context/AuthContextAPI";
import { checkNetworkReachable } from "../../../../utils/checkNetworkReachable";
import ExpensesTable from "./ExpensesTable";
import cloneDeep from 'lodash/cloneDeep';

let uuid = require("uuid");

const ContractManagerDealForm = ({
  isExistingContractForm,
  isEditMode,
  changeShowPage,
  recordData,
  dealDashboard = false,
  dealFormPrefillData = [],
  setDealFormPrefillData = () => { },
  hasDealFormPrefillData,
  selectedDealType,
  missingDspPage,
  landingPageTitle = "Contracts",
}) => {
  // api hook
  let api = useAxios();
  const { logoutUser } = useContext(AuthContextAPI);
  // cancel token used to cancel the call if component is unmounted or need to programmatically cancel the request
  const cancelTokenSource = axios.CancelToken.source();
  let theme = useTheme();
  let initialFormData = {
    key: uuid.v4(),
    id: "New",
    dealType: "",
    dealtypeid: "",
    dealsubcategory: "",
    labelid: "",
    releaseid: "",
    release_trackid: "",
    assetid: "",
    channelid: "",
    label_title_name: "",
    revenue_data: [
      {
        key: uuid.v4(),
        id: "New",
        clientid: "",
        client_name: "",
        clientType: "",
        isOpen: false,
        revenueshare: "",
      },
      {
        key: uuid.v4(),
        id: "New",
        clientid: "",
        client_name: "",
        clientType: "",
        isOpen: false,
        revenueshare: "",
      },
    ],
    advance_data: [],
    expense_data: [],
    action: TABLE_ACTION.add,
  };

  let editDropdownFormInitialization = {
    show: false,
    editCategory: "",
    obj: { name: "", value: "", title: "" },
  };

  const { formData, setFormData } = useContext(ContractFormContextAPI);

  const [isLoading, setIsLoading] = useState(false);

  const [message, setMessage] = useState({
    show: false,
    type: "",
    message: "",
  });
  const [addLabelModal, setAddLabelModal] = useState(false);
  const [showEditConfirmation, setShowEditConfirmation] = useState(false);
  const [originalFromdata, setOriginalFromdata] = useState({});
  const [dealFormData, setDealFormData] = useState({ ...initialFormData });
  const [editDropdownForm, setEditDropdownForm] = useState({
    ...editDropdownFormInitialization,
  });

  const [dropdownOptions, setDropdownOptions] = useState({
    labelOptions: [],
    releaseOptions: [],
    trackOnUpsOptions: [],
    serviceProdiverOptions: [],
    clientOptions: [],
    subCategoryOptions: [],
    dealTypeOptions: { deal_category_options: [], sub_deal_category_dict: {} },
    assetOptions: [],
    channelOptions: [],
  });
  const [step, setStep] = useState(0);
  const [missingDspDealsArrayData, setMissingDspDealsArrayData] = useState([]);

  const {
    labelOptions,
    releaseOptions,
    trackOnUpsOptions,
    serviceProdiverOptions,
    clientOptions,
    subCategoryOptions,
    dealTypeOptions,
    assetOptions,
    channelOptions,
  } = dropdownOptions;

  const { dealType, label_title_name, dealtypeid } = dealFormData;

  useEffect(() => {
    let isMoulded = true;
    if (isMoulded) {
      if (
        dealType === "" &&
        serviceProdiverOptions.length === 0 &&
        clientOptions.length === 0
      ) {
        getOptions();
      } else if (dealType === DEAL_TYPES.LABEL) {
        if (labelOptions.length === 0) {
          getLabel();
        }
      } else if (dealType === DEAL_TYPES.RELEASE) {
        if (releaseOptions.length === 0) {
          getReleaseOptions();
        }
      } else if (dealType === DEAL_TYPES.TRACK_ON_UPC) {
        if (trackOnUpsOptions.length === 0) {
          getTracks();
        }
      } else if (dealType === DEAL_TYPES.ASSET && assetOptions.length === 0) {
        getAssets();
      } else if (
        dealType === DEAL_TYPES.CHANNEL &&
        channelOptions.length === 0
      ) {
        getChannels();
      }
      let options = dealTypeOptions?.sub_deal_category_dict[dealType];
      setDropdownOptions({
        ...dropdownOptions,
        subCategoryOptions: options,
      });
    }
    return () => {
      isMoulded = false;
      cancelTokenSource.cancel();
    };
  }, [dealType]);

  const setMultipleSelectArray = () => {
    if (hasDealFormPrefillData) {
      let tempData = dealFormPrefillData.map((el) => {
        let tempData = {
          ...dealFormData,
          key: uuid.v4(),
          revenue_data: [
            {
              key: uuid.v4(),
              id: "New",
              clientid: "",
              client_name: "",
              clientType: "",
              isOpen: false,
              revenueshare: "",
            },
            {
              key: uuid.v4(),
              id: "New",
              clientid: "",
              client_name: "",
              clientType: "",
              isOpen: false,
              revenueshare: "",
            },
          ],
        };
        if (selectedDealType === DEAL_TYPES.LABEL) {
          return {
            ...tempData,
            labelid: el?.id,
            label_title_name: el?.labelname,
          };
        } else if (selectedDealType === DEAL_TYPES.RELEASE) {
          return {
            ...tempData,
            releaseid: el?.release_id,
            label_title_name: `${el?.release_title} • ${el?.release_upc}`,
          };
        }
        return {
          ...tempData,
          release_trackid: el?.release_track_upc_id,
          label_title_name: `${el?.track_title} • ${el?.release_upc}${el?.track_isrc}`,
        };
      });
      setMissingDspDealsArrayData(tempData);
      setDealFormData(tempData[0]);
    }
  };

  const nextStep = () => {
    setStep(step + 1);
    let tempData = [...missingDspDealsArrayData];
    let tempFormData = { ...dealFormData };
    tempData.splice(step, 1, tempFormData);
    setMissingDspDealsArrayData(tempData);
    setDealFormData(tempData[step + 1]);
  };

  const backStep = () => {
    setStep(step - 1);
    let tempData = [...missingDspDealsArrayData];
    let tempFormData = { ...dealFormData };
    tempData.splice(step, 1, tempFormData);
    tempData[step] = { ...dealFormData };
    setMissingDspDealsArrayData(tempData);
    setDealFormData(tempData[step - 1]);
  };

  const onSubmitMissingDspDeals = () => {
    let tempData = [...missingDspDealsArrayData];
    let tempFormData = { ...dealFormData };
    tempData.splice(step, 1, tempFormData);
    let temp = Object.assign({}, formData);
    tempData.forEach((el) => temp.deals_data.push(el));
    setFormData(temp);
    gotToContractForm();
  };

  const closeShowEditConfirmationWindow = () => {
    setShowEditConfirmation(false);
  };
  const openShowEditConfirmationWindow = () => {
    setShowEditConfirmation(true);
  };

  const closeEditDropdownForm = () => {
    setEditDropdownForm({ ...editDropdownFormInitialization });
  };
  const openEditDropdownForm = (editCategory) => {
    setEditDropdownForm({
      ...editDropdownForm,
      show: true,
      editCategory: editCategory,
    });
  };

  const onChangeEditForm = (name, value, title) => {
    setEditDropdownForm({
      ...editDropdownForm,
      obj: { name: name, value: value, title: title },
    });
  };

  const onSubmitEditForm = () => {
    onChange(
      editDropdownForm.obj.name,
      editDropdownForm.obj.value,
      editDropdownForm.obj.title
    );
    closeEditDropdownForm();
  };

  const prePopulateForm = () => {
    let tempData = {};

    const clonedDataOriginal = cloneDeep(recordData);

    setOriginalFromdata(clonedDataOriginal);

    let tempRecordData = cloneDeep(recordData);

    if (tempRecordData.labelid && tempRecordData.labelid !== "") {
      tempData = { dealType: DEAL_TYPES.LABEL };
    } else if (tempRecordData.releaseid && tempRecordData.releaseid !== 0) {
      tempData = { dealType: DEAL_TYPES.RELEASE };
    } else if (tempRecordData.assetid && tempRecordData.assetid !== "") {
      tempData = { dealType: DEAL_TYPES.ASSET };
    } else if (tempRecordData.channelid && tempRecordData.channelid !== "") {
      tempData = { dealType: DEAL_TYPES.CHANNEL };
    } else {
      tempData = { dealType: DEAL_TYPES.TRACK_ON_UPC };
    }
    tempRecordData["revenue_data"] =
      tempRecordData?.revenue_data &&
      tempRecordData.revenue_data.map((item) => ({
        ...item,
        key: uuid.v4(),
      }));
    setDealFormData({ ...tempRecordData, ...tempData });
  };

  const getTracks = async () => {
    setIsLoading(true);
    const handleSuccessResponse = (res) => {
      let options = dealTypeOptions?.sub_deal_category_dict[dealType];
      setDropdownOptions({
        ...dropdownOptions,
        trackOnUpsOptions: res,
        subCategoryOptions: options,
      });
    };

    await api
      .get(TRACK_URL, {
        cancelToken: cancelTokenSource.token,
      })
      .then((res) => {
        handleSuccessResponse(res.data);
      })
      .catch((error) => {
        checkNetworkReachable();
        if (error?.response?.status === 401) {
          logoutUser();
        } else if (!axios.isCancel(error) && error?.response) {
          alert(DEFAULT_ERROR_MSG);
        }
      });
    setMultipleSelectArray();
    setIsLoading(false);
  };

  const getLabel = async () => {
    setIsLoading(true);
    const handleSuccessResponse = (res) => {
      let options = dealTypeOptions?.sub_deal_category_dict[dealType];
      setDropdownOptions({
        ...dropdownOptions,
        labelOptions: res,
        subCategoryOptions: options,
      });
    };

    await api
      .get(LABELS_URL, {
        cancelToken: cancelTokenSource.token,
      })
      .then((res) => {
        handleSuccessResponse(res.data);
      })
      .catch((error) => {
        checkNetworkReachable();
        if (error?.response?.status === 401) {
          logoutUser();
        } else if (!axios.isCancel(error) && error?.response) {
          alert(DEFAULT_ERROR_MSG);
        }
      });
    setMultipleSelectArray();
    setIsLoading(false);
  };

  const getReleaseOptions = async () => {
    setIsLoading(true);
    const handleSuccessResponse = (res) => {
      let options = dealTypeOptions?.sub_deal_category_dict[dealType];
      setDropdownOptions({
        ...dropdownOptions,
        releaseOptions: res,
        subCategoryOptions: options,
      });
    };

    await api
      .get(CONTRACTS_RELEASES_URL, {
        cancelToken: cancelTokenSource.token,
      })
      .then((res) => {
        handleSuccessResponse(res.data);
      })
      .catch((error) => {
        checkNetworkReachable();
        if (error?.response?.status === 401) {
          logoutUser();
        } else if (!axios.isCancel(error) && error?.response) {
          alert(DEFAULT_ERROR_MSG);
        }
      });
    setMultipleSelectArray();
    setIsLoading(false);
  };

  const getAssets = async () => {
    setIsLoading(true);
    const handleSuccessResponse = (res) => {
      let options = dealTypeOptions?.sub_deal_category_dict[dealType];
      setDropdownOptions({
        ...dropdownOptions,
        assetOptions: res,
        subCategoryOptions: options,
      });
    };

    await api
      .get(GET_ALL_ASSET_DROPDOWN_URL, {
        cancelToken: cancelTokenSource.token,
      })
      .then((res) => {
        handleSuccessResponse(res.data);
      })
      .catch((error) => {
        checkNetworkReachable();
        if (error?.response?.status === 401) {
          logoutUser();
        } else if (!axios.isCancel(error) && error?.response) {
          alert(DEFAULT_ERROR_MSG);
        }
      });
    setIsLoading(false);
  };

  const getChannels = async () => {
    setIsLoading(true);
    const handleSuccessResponse = (res) => {
      let options = dealTypeOptions?.sub_deal_category_dict[dealType];
      setDropdownOptions({
        ...dropdownOptions,
        channelOptions: res,
        subCategoryOptions: options,
      });
    };

    await api
      .get(GET_CHANNEL_DROPDOWN_URL, {
        cancelToken: cancelTokenSource.token,
      })
      .then((res) => {
        handleSuccessResponse(res.data);
      })
      .catch((error) => {
        checkNetworkReachable();
        if (error?.response?.status === 401) {
          logoutUser();
        } else if (!axios.isCancel(error) && error?.response) {
          alert(DEFAULT_ERROR_MSG);
        }
      });
    setIsLoading(false);
  };

  const getOptions = async () => {
    setIsLoading(true);
    let contracttypeid = formData?.contracttypeid || recordData?.contracttypeid;
    let endpoints = [
      GET_ALL_SERVICE_PROVIDER,
      CONTRACTS_REGISTRATION,
      `${GET_DEAL_TYPE_URL}${contracttypeid}/`,
    ];
    await axios
      .all(
        endpoints.map((endpoint) =>
          api.get(endpoint, {
            cancelToken: cancelTokenSource.token,
          })
        )
      )
      .then(
        axios.spread(
          (
            { data: serviceProdiverOptionsData },
            { data: clientOptionsData },
            { data: dealTypeOptions }
          ) => {
            setDropdownOptions({
              ...dropdownOptions,
              serviceProdiverOptions: serviceProdiverOptionsData,
              clientOptions: clientOptionsData,
              dealTypeOptions: dealTypeOptions[0],
            });
          }
        )
      )
      .catch((error) => {
        checkNetworkReachable();
        if (error?.response?.status === 401) {
          logoutUser();
        } else if (!axios.isCancel(error) && error?.response) {
          alert(DEFAULT_ERROR_MSG);
        }
      });

    if (isEditMode) {
      prePopulateForm();
    } else if (hasDealFormPrefillData) {
      onChange("dealType", selectedDealType);
    } else {
      setIsLoading(false);
    }
  };

  const resetMessageObj = () => {
    setMessage({
      show: false,
      type: "",
      message: "",
    });
  };

  const resetForm = { ...initialFormData };

  const onChange = (name, value, title) => {
    if (message.show) {
      resetMessageObj();
    }
    let setData = { [name]: value };
    if (name === "dealType") {
      setData = { ...resetForm, ...setData };
    } else if (
      name === "labelid" ||
      name === "releaseid" ||
      name === "release_trackid" ||
      name === "assetid" ||
      name === "channelid"
    ) {
      setData = { ...setData, label_title_name: title };
    }
    setDealFormData({ ...dealFormData, ...setData });
  };

  const openAddLabelModal = () => {
    setAddLabelModal(true);
  };

  const closeAddLabelModal = () => {
    setAddLabelModal(false);
  };

  const onlabelAddedSuccess = () => {
    closeAddLabelModal();
    getLabel();
  };

  const gotToLandingPage = () => {
    changeShowPage(config.pages.LANDING);
    setDealFormPrefillData([]);
  };

  const getAdvanceData = (advances) => {
    onChange("advance_data", advances);
  };

  const getExpenseData = (advances) => {
    onChange("expense_data", advances);
  };

  const gotToContractForm = () => {
    changeShowPage(config.pages.CONTRACT_FORM);
    setDealFormPrefillData([]);
  };

  const breadCrumbData = [
    {
      title:
        hasDealFormPrefillData || missingDspPage
          ? missingDspDealsConfig.Title
          : dealDashboard
            ? "Deals"
            : landingPageTitle,
      onClickPress: !hasDealFormPrefillData && gotToLandingPage,
    },
    {
      title: isExistingContractForm ? "Edit Contract" : "New Contract",
      onClickPress: !hasDealFormPrefillData && gotToContractForm,
    },
    {
      title: isEditMode
        ? `Edit Deal #${recordData?.id}` 
        : `New Deal ${hasDealFormPrefillData
          ? `(${step + 1}/${dealFormPrefillData.length})`
          : ""
        }`,
      active: true,
    },
  ];
  if (dealDashboard) {
    breadCrumbData.splice(1, 1);
  }

  const getselectedClientValue = (name, key, value, selectedOpt) => {
    if (message.show) {
      resetMessageObj();
    }
    let temp = { ...dealFormData };
    let clientObj = temp.revenue_data.find((obj) => obj.key === key);
    clientObj[name] = value;
    if (name === "clientid") {
      clientObj["client_name"] = selectedOpt?.name || selectedOpt?.companyname;
      clientObj["clientType"] = selectedOpt?.clientType;
    }
    if (isEditMode) {
      if (clientObj.id !== "New") {
        clientObj.action = TABLE_ACTION.update;
      } else {
        clientObj.action = TABLE_ACTION.add;
      }
    }
    setDealFormData(temp);
  };

  const openCloseClientModal = (key, isOpen) => {
    let temp = { ...dealFormData };
    let clientObj = temp.revenue_data.find((obj) => obj.key === key);
    clientObj.isOpen = isOpen;
    setDealFormData(temp);
  };

  const handleAddBtnClick = () => {
    let temp = { ...dealFormData };
    let obj = {
      id: "New",
      key: uuid.v4(),
      clientid: "",
      client_name: "",
      clientType: "",
      isOpen: false,
      revenueshare: "",
    };
    temp.revenue_data.push(obj);
    setDealFormData(temp);
  };

  const onChangeValue = (selectname, value, title, selectedOpt) => {
    getselectedClientValue(
      selectname,
      dealFormData.revenue_data[0].key,
      value,
      selectedOpt
    );
  };

  const handleRemoveBtnClick = (key) => {
    let temp = { ...dealFormData };
    let filteredData = [];
    if (isEditMode) {
      filteredData = temp.revenue_data.find((data) => data.key === key);

      if (filteredData.id === "New") {
        filteredData = temp.revenue_data.filter((data) => data.key !== key);
        temp.revenue_data = filteredData;
      } else {
        filteredData.action = TABLE_ACTION.delete;
      }
    } else {
      filteredData = temp.revenue_data.filter((data) => data.key !== key);
      temp.revenue_data = filteredData;
    }
    setDealFormData(temp);
  };

  const setContractDealData = () => {
    let temp = Object.assign({}, formData);
    if (isEditMode) {
      let obj = temp.deals_data.findIndex((item) =>
        dealFormData.id === "New"
          ? item.key === dealFormData.key
          : item.id === dealFormData.id
      );
      temp.deals_data[obj] = {
        ...dealFormData,
        action:
          dealFormData.id === "New" ? TABLE_ACTION.add : TABLE_ACTION.update,
      };
    } else {
      temp.deals_data.push(dealFormData);
    }
    setFormData(temp);
    gotToContractForm();
  };

  const callEditDealApi = async () => {
    let tempFormData = { ...dealFormData, action: TABLE_ACTION.update };
    let requestData = {
      original: originalFromdata,
      revised: tempFormData,
      compare_data: true,
    };
    if (showEditConfirmation) {
      requestData.compare_data = false;
      closeShowEditConfirmationWindow();
    }
    let url = `${DEAL_DASHBOARD_URL}${recordData?.id}/`;

    setIsLoading(true);
    const handleSuccessResponse = (res) => {
      if (res?.msg) {
        alert(res.msg);
      }
      gotToContractForm();
    };

    const handleErrorResponse = (error) => {
      checkNetworkReachable();
      if (error?.response?.status === 401) {
        logoutUser();
      } else if (!axios.isCancel(error) && error?.response) {
        setIsLoading(false);
        if (error?.response.status === 409) {
          openShowEditConfirmationWindow();
        } else {
          alert(error?.response?.data?.msg || DEFAULT_ERROR_MSG);
        }
      }
    };

    await api
      .put(url, requestData, {
        cancelToken: cancelTokenSource.token,
      })
      .then((res) => {
        handleSuccessResponse(res.data);
      })
      .catch((error) => {
        handleErrorResponse(error);
      });
  };

  const onSubmit = (action = null) => {
    const revenueShareValues = findAllByKey(
      dealFormData.revenue_data,
      "revenueshare"
    );
    const revenueShareClientID = findAllByKey(
      dealFormData.revenue_data,
      "clientid"
    );

    let submitFormValidation = {
      dealtypeid,
      dealType,
      label_title_name,
      revenue_data_revenue: revenueShareValues.every((el) => el !== "")
        ? revenueShareValues
        : "",
      revenue_data_clientid: revenueShareClientID.every((el) => el !== "")
        ? revenueShareClientID
        : "",
    };
    if (checkObjectIsEmpty(submitFormValidation)) {
      const sumRevenueShareValues = revenueShareValues.reduce(
        (a, b) => Number(a) + Number(b),
        0
      );
      if (sumRevenueShareValues !== 100) {
        alert("Revenue Share total should be equal 100");
      } else {
        if (dealDashboard) {
          callEditDealApi();
        } else if (hasDealFormPrefillData) {
          if (action === "next") {
            nextStep();
          } else if (action === "back") {
            backStep();
          } else {
            onSubmitMissingDspDeals();
          }
        } else {
          setContractDealData();
        }
      }
    } else {
      setMessage({
        show: true,
        type: MESSAGE_TYPE.error,
        message: DEFAULT_REQUIRED_VALIDATION_MESSAGE,
      });
    }
  };

  return (
    <WebContentContainerWrapper
      breadCrumbData={breadCrumbData}
      loading={isLoading}
    >
      <View style={commonFormStyle(theme).formContainer}>
        <FormSelect
          required
          name="dealType"
          label={"Select Deal Category"}
          options={dealTypeOptions?.deal_category_options}
          onChange={onChange}
          value={dealType}
          disabled={isEditMode || hasDealFormPrefillData}
          isValidated={message.show && !dealType}
        />
        {!!dealType && (
          <FormSelect
            required
            name={"dealtypeid"}
            label={"Select Deal Subcategory"}
            options={subCategoryOptions}
            value={dealFormData?.dealtypeid}
            onChange={onChange}
            placeholdertext={"Deal Subcategory"}
            isValidated={message.show && !dealFormData?.dealtypeid}
          />
        )}
        {!!dealType && dealType === DEAL_TYPES.LABEL && (
          <>
            <View style={commonFormStyle(theme).inLineFormContainer}>
              {isEditMode ? (
                <>
                  <FormControlInput
                    required
                    label={"Select Label"}
                    name={"labelid"}
                    value={dealFormData.label_title_name}
                    disabled
                    isValidated={message.show && !dealFormData.label_title_name}
                    showHelpText={false}
                  />
                  <ActionsButtonIcons
                    customStyle={commonFormStyle(theme).inLineFormBtn}
                    size={46}
                    buttonType={ACTION_BUTTON_TYPE.EDIT}
                    handleOnPress={() => {
                      openEditDropdownForm("Label");
                    }}
                  />
                </>
              ) : (
                <FormSelect
                  required
                  searchable
                  name={"labelid"}
                  label={"Select Label"}
                  options={labelOptions}
                  value={dealFormData.labelid}
                  onChange={onChange}
                  optionName="labelname"
                  optionValue="id"
                  placeholdertext={"Label Name"}
                  disabled={hasDealFormPrefillData}
                  isValidated={message.show && !dealFormData.labelid}
                  showHelpText={false}
                />
              )}
              {!hasDealFormPrefillData && (
                <CustomButton
                  onPress={openAddLabelModal}
                  text={"Create New label"}
                  cutomButtonStyle={commonFormStyle(theme).inLineFormBtn}
                />
              )}
            </View>
            <MessageValidation
              fieldValidation={message.show && !dealFormData.label_title_name}
              message={FORM_REQUIRED_FIELD_MSG}
            />
            <CustomModal
              title="New Label"
              visible={addLabelModal}
              hideModal={closeAddLabelModal}
            >
              <LabelForm
                submitSuccess={onlabelAddedSuccess}
                cancelButton={closeAddLabelModal}
              />
            </CustomModal>
          </>
        )}
        {!!dealType &&
          dealType === DEAL_TYPES.RELEASE &&
          (isEditMode ? (
            <View style={commonFormStyle(theme).inLineFormContainer}>
              <FormControlInput
                required
                label={"Select Release"}
                name={"releaseid"}
                value={dealFormData.label_title_name}
                disabled
                isValidated={message.show && !dealFormData.label_title_name}
              />
              <ActionsButtonIcons
                customStyle={commonFormStyle(theme).inLineFormBtn}
                size={46}
                buttonType={ACTION_BUTTON_TYPE.EDIT}
                handleOnPress={() => {
                  openEditDropdownForm("Release");
                }}
              />
            </View>
          ) : (
            <FormSelect
              required
              name="releaseid"
              label={"Select Release"}
              options={releaseOptions}
              onChange={onChange}
              searchable
              value={dealFormData.releaseid}
              optionName="display_name"
              optionValue="id"
              placeholdertext={"Release Title • UPC"}
              disabled={hasDealFormPrefillData}
              isValidated={message.show && !dealFormData.releaseid}
            />
          ))}
        {!!dealType &&
          dealType === DEAL_TYPES.TRACK_ON_UPC &&
          (isEditMode ? (
            <View style={commonFormStyle(theme).inLineFormContainer}>
              <FormControlInput
                required
                label={"Select Track on UPC"}
                name={"release_trackid"}
                value={dealFormData.label_title_name}
                disabled
                isValidated={message.show && !dealFormData.label_title_name}
              />
              <ActionsButtonIcons
                customStyle={commonFormStyle(theme).inLineFormBtn}
                size={46}
                buttonType={ACTION_BUTTON_TYPE.EDIT}
                handleOnPress={() => {
                  openEditDropdownForm("Track on UPC");
                }}
              />
            </View>
          ) : (
            <FormSelect
              required
              name="release_trackid"
              searchable
              label={"Select Track on UPC"}
              options={trackOnUpsOptions}
              onChange={onChange}
              value={dealFormData.release_trackid}
              optionName="display_name"
              optionValue="id"
              placeholdertext={"Track Title • UPCISRC"}
              disabled={hasDealFormPrefillData}
              isValidated={message.show && !dealFormData.release_trackid}
            />
          ))}
        {!!dealType && dealType === DEAL_TYPES.ASSET && (
          <FormSelect
            required
            name="assetid"
            searchable
            label={"Select Asset"}
            options={assetOptions}
            onChange={onChange}
            value={dealFormData.assetid}
            optionName="display_name"
            optionValue="id"
            placeholdertext={"Asset ID • Asset Title"}
            // disabled={isEditMode}
            isValidated={message.show && !dealFormData.assetid}
          />
        )}
        {!!dealType && dealType === DEAL_TYPES.CHANNEL && (
          <FormSelect
            required
            name="channelid"
            searchable
            label={"Select Channel"}
            options={channelOptions}
            onChange={onChange}
            value={dealFormData.channelid}
            optionName="display_name"
            optionValue="id"
            placeholdertext={"Channel ID • Channel Title"}
            // disabled={isEditMode}
            isValidated={message.show && !dealFormData.channelid}
          />
        )}
        {(!!dealType || isEditMode) && (
          <View style={commonFormStyle(theme).inLineFormSelectInputContainer}>
            <FormSelect
              required
              searchable
              name="clientid"
              label={"Select Service Provider"}
              options={serviceProdiverOptions}
              value={dealFormData.revenue_data[0].clientid}
              onChange={onChangeValue}
              optionName="companyname"
              optionValue="clientid"
              // disabled={isEditMode}
              isValidated={
                message.show && !dealFormData.revenue_data[0].clientid
              }
            />
            <View style={commonFormStyle(theme).inLineFormField}>
              <FormControlInput
                required
                name="revenueshare"
                label="Revenue Share (%)"
                onChange={onChangeValue}
                value={dealFormData.revenue_data[0].revenueshare}
                isValidated={
                  message.show && !dealFormData.revenue_data[0].revenueshare
                }
              />
            </View>
          </View>
        )}
        {(!!dealType || isEditMode) &&
          dealFormData?.revenue_data
            .slice(1)
            .map((data, index) => (
              <AddMoreField
                name1="clientid"
                name2="revenueshare"
                key={index}
                index={index}
                label1={"Select Client"}
                label2={"Revenue Share (%)"}
                action={data.action || TABLE_ACTION.no_action}
                options={clientOptions}
                dataObj={data}
                handleAddMoreBtnClick={handleAddBtnClick}
                handleRemoveMoreBtnClick={handleRemoveBtnClick}
                openCloseClientModal={openCloseClientModal}
                getselectedValue={getselectedClientValue}
                isValidated={message.show}
              />
            ))}
        <MessageValidation {...message} />
      </View>
      {!!dealType && (
        <View style={commonFormStyle(theme).tableFormFieldContainer}>
          <AdvancesTable
            advanceFor={ADVANCES_TYPES.DEAL}
            getAdvanceData={getAdvanceData}
            advancesData={dealFormData.advance_data}
          />
        </View>
      )}
      {!!dealType && (
        <View style={commonFormStyle(theme).tableFormFieldContainer}>
          <ExpensesTable
            expenseFor={ADVANCES_TYPES.CONTRACT}
            getExpenseData={getExpenseData}
            expensesData={dealFormData.expense_data}
          />
        </View>
      )}
      {!!dealType && (
        <View style={commonFormStyle(theme).formContainer}>
          {!hasDealFormPrefillData ? (
            <View style={commonFormStyle(theme).actionBtnContainer}>
              <CustomButton
                onPress={onSubmit}
                text={BUTTON_TEXTS.SUBMIT}
                cutomButtonStyle={commonFormStyle(theme).submitBtn}
              />
              <CustomButton
                text={BUTTON_TEXTS.BACK}
                onPress={gotToContractForm}
                cutomButtonStyle={commonFormStyle(theme).backBtn}
              />
            </View>
          ) : (
            <View
              style={{ flexDirection: "row", alignItems: "center", gap: 10 }}
            >
              <Pressable
                disabled={step === 0}
                onPress={() => {
                  onSubmit("back");
                }}
              >
                <AntDesign
                  name="left"
                  size={24}
                  color={
                    step === 0
                      ? theme.colors.disableBtnIcon
                      : theme.colors.black
                  }
                />
              </Pressable>
              <Pressable
                disabled={step === dealFormPrefillData.length - 1}
                onPress={() => {
                  onSubmit("next");
                }}
              >
                <AntDesign
                  name="right"
                  size={24}
                  color={
                    step === dealFormPrefillData.length - 1
                      ? theme.colors.disableBtnIcon
                      : theme.colors.black
                  }
                />
              </Pressable>
              {step === dealFormPrefillData.length - 1 && (
                <CustomButton
                  onPress={onSubmit}
                  text={BUTTON_TEXTS.SUBMIT}
                  cutomButtonStyle={commonFormStyle(theme).submitBtn}
                />
              )}
              <CustomButton
                text={BUTTON_TEXTS.BACK_TO_MISSING_DSP_DEALS}
                onPress={() => {
                  changeShowPage(config.pages.LANDING);
                }}
                cutomButtonStyle={commonFormStyle(theme).backBtn}
              />
            </View>
          )}
        </View>
      )}
      <CustomModal
        title={`Confirm`}
        visible={showEditConfirmation}
        hideModal={closeShowEditConfirmationWindow}
      >
        <ConfirmationWindow
          message={`${CONDFIRM_EDIT_MESSAGE}`}
          handleNoOnClick={closeShowEditConfirmationWindow}
          handleYesOnClick={onSubmit}
        />
      </CustomModal>
      <CustomModal
        title={`Edit ${editDropdownForm.editCategory}`}
        visible={editDropdownForm.show}
        hideModal={closeEditDropdownForm}
      >
        <View style={commonFormStyle(theme).modalFormContainer}>
          {!!dealType && dealType === DEAL_TYPES.LABEL && (
            <FormSelect
              required
              searchable
              name={"labelid"}
              label={"Select Label"}
              options={labelOptions}
              value={editDropdownForm.obj.value}
              onChange={onChangeEditForm}
              optionName="labelname"
              optionValue="id"
              placeholdertext={"Label Name"}
              isValidated={message.show && !editDropdownForm.obj.value}
            />
          )}
          {!!dealType && dealType === DEAL_TYPES.RELEASE && (
            <FormSelect
              required
              searchable
              name="releaseid"
              label={"Select Release"}
              options={releaseOptions}
              onChange={onChangeEditForm}
              value={editDropdownForm.obj.value}
              optionName="display_name"
              optionValue="id"
              placeholdertext={"Release Title • UPC"}
              isValidated={message.show && !editDropdownForm.obj.value}
            />
          )}
          {!!dealType && dealType === DEAL_TYPES.TRACK_ON_UPC && (
            <FormSelect
              required
              searchable
              name="release_trackid"
              label={"Select Track on UPC"}
              options={trackOnUpsOptions}
              onChange={onChangeEditForm}
              value={editDropdownForm.obj.value}
              optionName="display_name"
              optionValue="id"
              placeholdertext={"Track Title • UPCISRC"}
              isValidated={message.show && !editDropdownForm.obj.value}
            />
          )}

          <View style={commonFormStyle(theme).actionBtnContainer}>
            <CustomButton
              onPress={onSubmitEditForm}
              text={BUTTON_TEXTS.SUBMIT}
              cutomButtonStyle={commonFormStyle(theme).yesBtn}
              disabled={editDropdownForm.obj.value === ""}
            />
            <CustomButton
              text={BUTTON_TEXTS.CANCEL}
              onPress={closeEditDropdownForm}
              cutomButtonStyle={commonFormStyle(theme).backBtn}
            />
          </View>
        </View>
      </CustomModal>
    </WebContentContainerWrapper>
  );
};

export default ContractManagerDealForm;
