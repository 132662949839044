import adminNavigationString from "../../navigation/AppStack/AdminAppDrawer/adminNavigationString";
import {
  Ionicons,
  FontAwesome5,
  MaterialIcons,
  MaterialCommunityIcons,
  Entypo,
} from "@expo/vector-icons";
import React from "react";

const CustomSideBarMenuIcon = ({ screenName, color }) => {
  let size = 24;
  switch (screenName) {
    case adminNavigationString.CLIENT_MANAGER.name:
      return (
        <FontAwesome5
          name="user-secret"
          style={{ width: size, height: size }}
          size={size}
          color={color}
        />
      );
    case adminNavigationString.CONTRACT_MANAGER.name:
      return (
        <FontAwesome5
          name="file-signature"
          style={{ width: size, height: size }}
          size={size}
          color={color}
        />
      );
    case adminNavigationString.DEALS.name:
      return (
        <FontAwesome5
          name="file-invoice"
          style={{ width: size, height: size, marginLeft: -2 }}
          size={size}
          color={color}
        />
      );
    case adminNavigationString.MISSING_DSP_DEALS.name:
      return (
        <MaterialCommunityIcons
          style={{ width: 28, height: 28, marginLeft: -4 }}
          name="file-question"
          size={28}
          color={color}
        />
      );
    case adminNavigationString.OVERLAPPING_DSP_DEALS.name:
      return (
        <MaterialCommunityIcons
          style={{ width: size, height: size }}
          name="vector-intersection"
          size={size}
          color={color}
        />
      );
    case adminNavigationString.CATALOGUE.name:
      return (
        <Entypo
          style={{ width: size, height: size }}
          name="folder-music"
          size={size}
          color={color}
        />
      );
    case adminNavigationString.ADVANCES.name:
      return (
        <MaterialIcons
          style={{ width: size, height: size }}
          name="payments"
          size={size}
          color={color}
        />
      );
    case adminNavigationString.EXPENSES.name:
      return (
        <MaterialCommunityIcons
          style={{ width: size, height: size }}
          name="cash-minus"
          size={size}
          color={color}
        />
      );
    case adminNavigationString.ACCOUNTS.name:
      return (
        <FontAwesome5
          style={{ width: 20, height: 20 }}
          name="user-plus"
          size={20}
          color={color}
        />
      );
    case adminNavigationString.EMPLOYEE_ACCOUNTS.name:
      return (
        <MaterialCommunityIcons
          style={{ width: 28, height: 28, marginLeft: -3 }}
          name="account-tie"
          size={28}
          color={color}
        />
      );
    case adminNavigationString.ROYALTIES.name:
      return (
        <MaterialCommunityIcons
          style={{ width: 26, height: 26 }}
          name="account-cash"
          size={26}
          color={color}
        />
      );
    case adminNavigationString.REPORTS.name:
      return (
        <Ionicons
          style={{ width: size, height: size }}
          name="bar-chart"
          size={size}
          color={color}
        />
      );
    case adminNavigationString.ADJUSTMENTS.name:
      return (
        <FontAwesome5
          style={{ width: size, height: size }}
          name="money-check-alt"
          size={size}
          color={color}
        />
      );
    case adminNavigationString.INVOICESDATA.name:
      return (
        <FontAwesome5
          style={{ width: size, height: size, marginLeft: -2 }}
          name="file-invoice-dollar"
          size={size}
          color={color}
        />
      );
    case adminNavigationString.REPORT_VERSION.name:
      return (
        <FontAwesome5
          style={{ width: size, height: size }}
          name="layer-group"
          size={size}
          color={color}
        />
      );
    case adminNavigationString.PAYMENTS.name:
      return (
        <MaterialIcons
          style={{ width: size, height: size }}
          name="payment" size={size}
          color={color} />
      );
    default:
      return (
        <Ionicons
          style={{ width: size, height: size }}
          name="document"
          size={size}
          color={color}
        />
      );
  }
};

export default CustomSideBarMenuIcon;
