import { FlatList, Text, TouchableHighlight, View } from "react-native";
import React, { useCallback, useState } from "react";
import { Menu, Searchbar, useTheme } from "react-native-paper";
import {
  MaterialCommunityIcons,
  MaterialIcons,
  Fontisto,
} from "@expo/vector-icons";
import {
  formSelectCustomStyle,
  formSelectStyle,
} from "../../../../components/FormControl/FormSelect/formSelectStyle";
import { commonSelectFilterstyles } from "./SingleSelectFilter";
import NoData from "../../../../components/NoData";
import { sortDynamic } from "../../../../utils/sortDynamic";
import { SORTDIRECTION } from "../../../../components/CommonDataTable/commonDataTable.config";

const MultiSelectFilter = ({
  name,
  title,
  multipleSelect = true,
  disabled = false,
  isForm = false,
  options = [],
  optionName = "title",
  optionValue = "value",
  placeholdertext = "placeholdertext",
  value = [],
  onChange = () => { },
  showEraser = false,
  disableOptions = []
}) => {
  let theme = useTheme();
  const [visible, setVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [widthOfTheDropdown, setWidthOfTheDropdown] = useState(0);

  const openMenu = () => setVisible(true);

  const closeMenu = () => {
    setVisible(false);
    if (searchTerm) {
      setSearchTerm("");
    }
  };

  const onItemSelection = (item) => {
    if (multipleSelect) {
      let tempValue = [...value];
      if (value.includes(item[optionValue])) {
        tempValue = tempValue.filter((el) => el !== item[optionValue]);
      } else {
        tempValue.push(item[optionValue]);
      }
      onChange(name, tempValue, item);
    } else {
      if (!(value.length > 0 && value[0] === item[optionValue])) {
        let temp = { [name]: [item[optionValue]], [title]: [item[optionName]] };
        onChange(temp, item);
      }
      closeMenu();
    }
  };

  const onPressCheckAll = () => {
    let allValues = [];
    if (value.length < options.length) {
      allValues = [...options].map((el) => el[optionValue]);
    }
    onChange(name, allValues);
  };

  const onClearAllFilter = () => {
    if (!disabled) {
      onChange({ [name]: [], [title]: [] });
    }
  };

  const getOptions = useCallback(() => {
    let filteredData = [...options];
    if (searchTerm !== "") {
      filteredData = [...options].filter(
        (element) =>
          element?.[optionName] &&
          element?.[optionName].toLocaleLowerCase().includes(searchTerm.trim().toLocaleLowerCase())
      );
    }
    let ascOptions = [...filteredData].sort(
      sortDynamic(optionName, SORTDIRECTION.ascending)
    );
    return ascOptions;
  }, [searchTerm, value.length, JSON.stringify(options), value]);

  const getValue = useCallback(() => {
    let label = "";
    if (value.length > 0) {
      label = options.find((el) => el[optionValue] === value[0]);
      return `: ${label[optionName]}`;
    }
    return label;
  }, [value]);

  const onLayout = (event) => {
    const { width } = event.nativeEvent.layout;
    setWidthOfTheDropdown(width);
  };

  return (
    <View style={formSelectCustomStyle.filterContainer}>
      <Menu
        visible={visible}
        onDismiss={closeMenu}
        style={formSelectStyle(theme, disabled).customDropdownContainer}
        anchor={
          <>
            <TouchableHighlight
              onLayout={onLayout}
              disabled={disabled}
              underlayColor={theme.colors.selectedTableBackgroundColor}
              style={
                commonSelectFilterstyles(
                  theme,
                  value.length > 0,
                  disabled,
                  null,
                  isForm
                ).btnContainer
              }
              onPress={openMenu}
            >
              <View style={commonSelectFilterstyles(theme).btnContentContainer}>
                <View
                  style={commonSelectFilterstyles(theme).buttonTxtPlaceholder}
                >
                  <Text
                    numberOfLines={1}
                    style={
                      commonSelectFilterstyles(theme, value.length > 0)
                        .customDropdownBtnText
                    }
                  >
                    {placeholdertext}
                    <Text
                      style={commonSelectFilterstyles(theme).selectedValueText}
                    >
                      {!multipleSelect
                        ? `${getValue()}`
                        : `: ${value.length}/${options.length}`}
                    </Text>
                  </Text>
                </View>
                <View
                  style={
                    commonSelectFilterstyles(theme).btnActionIconsContainer
                  }
                >
                  <MaterialIcons
                    name="keyboard-arrow-down"
                    size={24}
                    color={disabled ? theme.colors.disabled : theme.colors.text}
                  />
                  {(multipleSelect || showEraser) && (
                    <Fontisto
                      name="eraser"
                      size={14}
                      color={
                        !disabled && value.length > 0
                          ? theme.colors.black
                          : theme.colors.disabled
                      }
                      onPress={onClearAllFilter}
                    />
                  )}
                </View>
              </View>
            </TouchableHighlight>
          </>
        }
      >
        <View style={commonSelectFilterstyles(theme).searchCheckBoxContainer}>
          <Searchbar
            style={{ width: widthOfTheDropdown - 20 }}
            onChangeText={(query) => {
              setSearchTerm(query);
            }}
            value={searchTerm}
          />
          {multipleSelect && (
            <MaterialCommunityIcons
              onPress={onPressCheckAll}
              name={
                options.length > 0 && value.length === options.length
                  ? "checkbox-multiple-marked"
                  : "checkbox-multiple-blank-outline"
              }
              size={24}
              color={
                options.length > 0 && value.length === options.length
                  ? theme.colors.primary
                  : theme.colors.black
              }
            />
          )}
        </View>

        <FlatList
          ListEmptyComponent={<NoData />}
          data={getOptions()}
          keyExtractor={(item, index) => index}
          renderItem={({ item }) => {
            let disableMenuItem = disableOptions.length > 0 && disableOptions.includes(item[optionName])
            return <Menu.Item
              disabled={disableMenuItem}
              style={
                commonSelectFilterstyles(
                  theme,
                  value.includes(item[optionValue]),
                  disabled,
                  widthOfTheDropdown
                ).menuItemStyle
              }
              onPress={() => {
                onItemSelection(item);
              }}
              title={item[optionName]}
              titleStyle={
                commonSelectFilterstyles(
                  theme,
                  value.includes(item[optionValue]),
                  disabled,
                  widthOfTheDropdown - 20,
                  isForm,
                  disableMenuItem
                ).titleStyle
              }
            />
          }}
          style={{
            height: options.length > 5 ? 250 : null,
            width: widthOfTheDropdown,
          }}
        />
      </Menu>
    </View>
  );
};

export default MultiSelectFilter;
