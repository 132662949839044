import { View } from "react-native";
import React, { useState } from "react";
import { Searchbar, useTheme } from "react-native-paper";
import { commonstyle } from "../Accounts/AccountEditPage/commonstyle";
import { configContract } from "./missingDspDeals.config";
import {
  ActionsButtonIcons,
  CommonDataTable,
  commonStatisticButtonStyle,
} from "../../../components";
import { ACTION_BUTTON_TYPE } from "../../../constants/GlobalVariables";
import { AntDesign, Entypo } from "@expo/vector-icons";
import dayjs from "dayjs";

const SelectContractTable = ({
  isLoading,
  data,
  getContractSelectedContract,
}) => {
  let theme = useTheme();
  const [searchQuery, setSearchQuery] = useState("");
  const onChangeSearch = (query) => setSearchQuery(query);

  let columns = [
    {
      key: "id",
      title: "Contract ID",
      dataIndex: "id",
      width: 0.3,
      sort: true,
      search: true,
    },
    {
      key: "client_name",
      title: "Client Name",
      dataIndex: "client_name",
      sort: true,
      width: 1,
      search: true,
    },
    {
      key: "contracttypename",
      title: "Contract Type",
      dataIndex: "contracttypename",
      sort: true,
      width: 0.9,
    },
    {
      key: "contractnumber",
      title: "Contract Number",
      dataIndex: "contractnumber",
      sort: true,
      search: true,
      width: 0.5,
    },
    {
      key: "contract_date",
      title: "Contract Date",
      dataIndex: "contract_date",
      sort: true,
      width: 0.5,
      isDate: true,
      customToolTip: (date) => {
        return date ? dayjs(date).format("DD/MM/YYYY") : null
      },
      render: (date) => {
        return date ? dayjs(date).format("DD/MM/YYYY") : null
      },
    },
    {
      key: "duration",
      title: "Duration (Months)",
      dataIndex: "duration",
      sort: true,
      width: 0.5,
    },
    {
      key: "renewal",
      title: "Renewal (Months)",
      dataIndex: "renewal",
      sort: true,
      width: 0.5,
    },
    {
      key: "autorenewal",
      title: "Auto-Renewal",
      dataIndex: "autorenewal",
      width: 0.4,
      sort: true,
      center: true,
      customToolTip: (autorenewal) => {
        return autorenewal ? "Yes" : "No";
      },
      render: (autorenewal) => {
        return autorenewal ? (
          <AntDesign name="check" size={16} color={theme.colors.primary} />
        ) : (
          <Entypo name="cross" size={16} color={theme.colors.error} />
        );
      },
    },
    {
      key: "remaining_days",
      title: "Remaining (Days)",
      dataIndex: "remaining_days",
      sort: true,
      width: 0.5,
    },
    {
      key: "total_deal",
      title: "Number of Deals",
      dataIndex: "total_deal",
      sort: true,
      width: 0.4,
    },
    {
      key: "total_works",
      title: "Total Works",
      dataIndex: "total_works",
      sort: true,
      width: 0.3,
    },
    {
      key: "advance_value",
      title: "Advance",
      dataIndex: "advance_value",
      sort: true,
      width: 0.25,
    },
    {
      key: "expense_value",
      title: "Expense",
      dataIndex: "expense_value",
      sort: true,
      width: 0.25,
    },
    {
      key: "pdf",
      title: "PDF",
      dataIndex: "pdf",
      sort: true,
      width: 0.1,
    },
    {
      key: ACTION_BUTTON_TYPE.ADD,
      title: "Add",
      dataIndex: "contracttypename",
      width: 0.1633,
      center: true,
      hideToolTip: true,
      render: (contracttypename, record) => {
        return (
          <ActionsButtonIcons
            buttonType={ACTION_BUTTON_TYPE.ADD}
            handleOnPress={() => {
              getContractSelectedContract(record);
            }}
          />
        );
      },
    },
  ];

  return (
    <View>
      <View style={commonstyle(theme).searchTableWrapper}>
        <View>
          <Searchbar
            placeholder={configContract.SEARCH_PLACEHOLDER}
            onChangeText={onChangeSearch}
            value={searchQuery}
            style={commonStatisticButtonStyle(theme, 760).searchBar}
            inputStyle={commonStatisticButtonStyle(theme).searchBarInput}
          />
        </View>
      </View>
      <CommonDataTable
        loading={isLoading}
        columns={columns}
        data={data}
        resetPage={searchQuery}
        searchQuery={searchQuery}
      />
    </View>
  );
};

export default SelectContractTable;
