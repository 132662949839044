import axios from "axios";
import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { View } from "react-native";
import { withTheme } from "react-native-paper";
import useAxios from "../../../../hooks/useAxios";
import { commonFormStyle, CustomButton, Loader } from "../../../../components";
import {
  FormControlInput,
  FormDatePicker,
  FormSelect,
} from "../../../../components/FormControl";
import MessageValidation from "../../../../components/MessageValidation";
import { CURRENCY_URL, EXPENSE_TYPE_URL } from "../../../../constants/GlobalUrls";
import {
  BUTTON_TEXTS,
  DEFAULT_ERROR_MSG,
  DEFAULT_FIELD_VALIDATION_MESSAGE,
  MESSAGE_TYPE,
} from "../../../../constants/GlobalVariables";
import { checkObjectIsEmpty } from "../../../../utils/checkObjectIsEmpty";
import { EXPENSES_TYPES, TABLE_ACTION } from "./ContractManagerDealForm.config";
import AuthContextAPI from "../../../../context/AuthContextAPI";
import { checkNetworkReachable } from "../../../../utils/checkNetworkReachable";
let uuid = require("uuid");

const ExpensesForm = ({
  data,
  cancelButton,
  getExpenseData,
  expenseFor = EXPENSES_TYPES.CLIENT,
  theme,
}) => {
  let isEditMode = Object.keys(data).length > 0;
  // api hook
  let api = useAxios();
  const { logoutUser } = useContext(AuthContextAPI);
  // cancel token used to cancel the call if component is unmounted or need to programmatically cancel the request
  const cancelTokenSource = axios.CancelToken.source();
  const [options, setOptions] = useState({ currenyOptions: [], typeOptions: [] });
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState({
    show: false,
    type: "",
    message: "",
  });

  const [formData, setFormData] = useState({
    key: uuid.v4(),
    id: "New",
    type: expenseFor,
    expensetypeid: "",
    expensetype: "",
    rawDate: "",
    expensedate: "",
    amount: 0,
    currencyid: "",
    action: TABLE_ACTION.add,
  });

  const { id, type, expensetypeid, expensetype, expensedate, amount, currencyid, rawDate } = formData;

  const { currenyOptions, typeOptions } = options

  useEffect(() => {
    let isMoulded = true;
    if (isMoulded) {
      if (currenyOptions.length === 0) {
        getAllOptions();
      }
      if (isEditMode) {
        let tempData = data;
        let d = data.expensedate && data.expensedate.split("/");
        let dat = new Date(d[2] + "/" + d[1] + "/" + d[0]);
        tempData = {
          ...tempData,
          rawDate: dat,
        };
        setFormData(tempData);
      }
    }
    return () => {
      isMoulded = false;
      cancelTokenSource.cancel();
    };
  }, [data]);

  const getAllOptions = async () => {
    setIsLoading(true);
    let endpoints = [
      { url: CURRENCY_URL, method: "get" },
      { url: EXPENSE_TYPE_URL, method: "get" },
    ];
    await axios
      .all(
        endpoints.map((endpoint) => {
          if (endpoint.method === "get") {
            return api.get(endpoint.url, {
              cancelToken: cancelTokenSource.token,
            });
          } else {
            return api.post(endpoint.url, {
              cancelToken: cancelTokenSource.token,
            });
          }
        })
      )
      .then(
        axios.spread(
          ({ data: currencyOptions }, { data: typeOptions }) => {
            setOptions({ currenyOptions: currencyOptions, typeOptions: typeOptions })
          }
        )
      )
      .catch((error) => {
        checkNetworkReachable();
        if (error?.response?.status === 401) {
          logoutUser();
        } else if (!axios.isCancel(error) && error?.response) {
          alert(DEFAULT_ERROR_MSG);
        }
      });
    setIsLoading(false);
  };

  const resetMessageObj = () => {
    setMessage({
      show: false,
      type: "",
      message: "",
    });
  };

  const onChange = (name, value, selectedName, option) => {
    if (message.show) {
      resetMessageObj();
    } if (name === "type") {
      resetMessageObj();
      setFormData({ ...formData, [name]: value });
    } else if (name === "expensetypeid") {
      setFormData({
        ...formData,
        [name]: value,
        expensetype: option?.expensetype,
      });
    } else if (name === "rawDate") {
      setFormData({
        ...formData,
        [name]: value,
        expensedate: dayjs(value).format("DD/MM/YYYY"),
      });
    } else if (name === "currencyid") {
      setFormData({
        ...formData,
        [name]: value,
        currencycode: selectedName,
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const onSubmit = async () => {
    let requiredFields = { id, type, expensetypeid, expensetype, expensedate, amount, currencyid };
    let isEmpty = checkObjectIsEmpty(requiredFields);
    if (isEmpty) {
      let submitFormData = formData;
      if (id !== "New") {
        submitFormData = { ...formData, action: TABLE_ACTION.update };
      }
      getExpenseData(submitFormData);
    } else {
      setIsLoading(false);
      setMessage({
        show: true,
        type: MESSAGE_TYPE.error,
        message: DEFAULT_FIELD_VALIDATION_MESSAGE,
      });
    }
  };

  return (
    <View style={commonFormStyle(theme).modalFormContainer}>
      <Loader loading={isLoading} />
      <FormControlInput
        label="Expense Category"
        name={"type"}
        value={type}
        onChange={onChange}
        disabled={true}
      />
      {formData.clientid && (
        <FormControlInput
          label="Category ID"
          name={"clientid"}
          value={formData.clientid}
          // onChange={onChange}
          disabled={true}
        />
      )}
      <FormSelect
        required
        label={"Expense Type"}
        options={typeOptions}
        onChange={onChange}
        value={expensetypeid}
        name={"expensetypeid"}
        optionName="expensetype"
        optionValue="id"
        isValidated={message.show && !expensetypeid}
      />
      <FormDatePicker
        required
        label="Expense Date"
        name={"rawDate"}
        value={rawDate}
        onChange={onChange}
        isValidated={message.show && !rawDate}
      />
      <FormControlInput
        required
        label="Amount"
        name={"amount"}
        value={amount}
        onChange={onChange}
        isValidated={message.show && !(amount || amount === 0)}
      />
      <FormSelect
        required
        label={"Select Currency"}
        options={currenyOptions}
        onChange={onChange}
        value={currencyid}
        name={"currencyid"}
        optionName="currencycode"
        optionValue="id"
        isValidated={message.show && !currencyid}
      />
      <MessageValidation {...message} />
      {!!!isLoading && (
        <View style={commonFormStyle(theme).actionBtnContainer}>
          <CustomButton
            onPress={onSubmit}
            text={BUTTON_TEXTS.SUBMIT}
            cutomButtonStyle={commonFormStyle(theme).submitBtn}
          />
          <CustomButton
            text={BUTTON_TEXTS.CANCEL}
            onPress={cancelButton}
            cutomButtonStyle={commonFormStyle(theme).backBtn}
          />
        </View>
      )}
    </View>
  );
};

export default withTheme(ExpensesForm);
