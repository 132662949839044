export const config = {
  TITLE: "Invoices",
  CLIENTS_TITLE: "Client",
  CLIENT_KEY: "client_id",
  CLIENT_NAME: "client_name",

  SERVICE_TITLE: "Service",
  SERVICE_KEY: "service_id",
  SERVICE_NAME: "service_name",

  REPORT_VERSION_TITLE: "Report Version",
  REPORT_VERSION_KEY: "report_version_id",
  REPORT_VERSION_NAME: "report_version_name",

  TABLE_SEARCH_PLACEHOLDER: "Search by ID/Name/Client ID/Client Name",
  TABLE_INVOICE_TO_PAYMENT_SEARCH_PLACEHOLDER: "Search by ID/Name"
};

export const STATISTICS_DATA = {
  TOTAL: "Total",
  PAID: "Paid",
  UNPAID: "Unpaid",
};

export const MAKE_THESE_STATUS_RED_COLOR = ["UNPAID", "PARTIALLY PAID"]

export const DISABLE_INVOICE_TYPE_DROPDOWN_FOR_VALUES = ["Generated"]